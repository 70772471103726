.App {
  display: flex;
  text-align: center;
  background-color: #000;
  color: white;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin-top:10%;
}

.host-instruction {
  position: absolute;
  top: 20px;
  text-align: center;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: calc(7px + 1vmin);
  z-index: 100;
}

.App-header svg {
  width:35%;
}

span.winner-text {
  position:relative;
  top:10px;
  font-size:calc(5px + 2vmin);
}

.banner {
  margin: 50px 0;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

form input, form button {
  font-size: calc(6px + 2vmin);
  padding: 5px 10px;
}

form input {
  margin-right:10px;
}

form input.blackUnderlined {
  color: #ffffff;
  background: black;
  border: none;
  border-bottom: 1px solid white;
  font-style: italic;
}

.small {
  font-size: calc(10px + 1vmin);
}

form input.blackUnderlined:focus {
  outline: none;
}

.qr {
  position:absolute;
  top: 20px;
  right:20px;
  z-index:100
}

form button {
  border-radius: 0;
  box-shadow: none;
  color: #000;
  background: #f2382c;
  border: none;
  text-transform:uppercase;
}

form button:active, form button:hover {
  background: #bb2a21;
}

form button:disabled {
  opacity: 0.6;
  background: #f2382c;
}

form#playerJoin {
  height:70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

form#playerJoin input {
  margin: 20px 0;
}

form#hostKey {
  display: flex;
  flex-direction: column;
}

form span.error {
  color:#f2382c;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: calc(5px + 1vmin);
  margin-bottom: 10px;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

span.loader {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: calc(5px + 2vmin);
}

ul.players {
  display: flex;
  justify-content: center;
  width:60%;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.players li {
  width: calc(40px + 2vmin);
  list-style-type: none;
  padding: 1px;
  overflow: hidden;
}

ul.players span.player-name {
  position:relative;
  font-size: calc(5px + 1vmin);
  top:-14px;
  white-space: nowrap;
}

div.avatar {
  width: inherit;
}

div.join-container {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:90%;
  padding: 50px 0;
}

span.playerJoined svg {
  width: 40%;
}